<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <el-button style="float: right;margin-left: 10px;" type="primary" @click="openprescriptiondialog()">新增处方</el-button>
      <el-button style="float: right" type="primary" @click="opendialog()">栏目管理</el-button>
    </div>
    <!--栏目管理-->
    <el-dialog
      title="目录管理"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-row :gutter="24">
        <el-col :span="24">
          <div class="without-outline">
            <el-button style="float: right" type="primary" @click="innerVisible = true">添加栏目</el-button>
          </div>
        </el-col>
      </el-row>
      <el-dialog
        :show-close="false"
        width="30%"
        :title="categoriesform._id ? '编辑' : '新增'"
        :visible.sync="innerVisible"
        :close-on-click-modal="false"
        append-to-body>
        <el-form ref="categoriesform" :model="categoriesform" label-width="80px">
          <el-form-item label="栏目名称">
            <el-input v-model="categoriesform.name"></el-input>
          </el-form-item>
          <el-form-item label="栏目权限">
            <el-select v-model="categoriesform.permissions" multiple placeholder="请选择该栏目的权限">
              <el-option
                v-for="permissionsitem in options"
                :key="permissionsitem._id"
                :label="permissionsitem.name"
                :value="permissionsitem._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上级栏目">
            <el-select v-model="categoriesform.subcategories" placeholder="请选择该菜单的权限">
              <el-option
                v-for="item in categoriesoptions"
                :key="item._id"
                :label="item.name"
                :value="item._id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="排序">
            <el-input v-model="categoriesform.sorting"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="closeinnerVisibledialog()">取 消</el-button>
          <el-button type="primary" v-if="!categoriesform._id" @click="categories(categoriesform)">保存</el-button>
          <el-button type="primary" v-if="categoriesform._id" @click="updatacategories(categoriesform)">更新栏目</el-button>
        </div>  
      </el-dialog>
      <el-table
        :data="categorieslist"
        border
        style="width: 100%; margin-bottom: 20px; margin-top: 20px"
        row-key="_id"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column type="selection"></el-table-column>
        <el-table-column prop="name" label="栏目名称"></el-table-column>
        <el-table-column prop="permissions" label="权限">
          <template slot-scope="scope">
            <span v-for="item in scope.row.permissions">《{{ item.name }}》</span>
          </template>
        </el-table-column>
        <el-table-column prop="sorting" label="排序"></el-table-column>
        <el-table-column prop="Creator" label="创建人"></el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="editcategories(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deletecategories(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        @current-change="zhcnhandlePageChange"
        @size-change="zhcnhandlePageSizeChange"
        :current-page="zhcncurrentPage"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="zhcnpageSize"
        layout="total, prev, pager, next"
        :total="zhcntotal"
      >
      </el-pagination>
    </el-dialog>
    <!--栏目管理-->
    <el-table
      :data="items"
      style="width: 100%; margin-bottom: 20px"
      row-key="_id"
      border
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="name" label="处方名称"> </el-table-column>
      <el-table-column prop="Creator.name" label="创建人"></el-table-column>
      <el-table-column
        prop="createdAt"
        label="创建时间"
        :formatter="formatTime"
      ></el-table-column>
      <el-table-column
        prop="updatedAt"
        label="修改时间"
        :formatter="formatTime"
      ></el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleedit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="deleteMedicineTemplates(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handlePageChange"
      @size-change="handlePageSizeChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog
      :title="prescriptionform._id ? '更新' : '添加'"
      :visible.sync="addprescriptiondialog"
      width="40%">
      <el-form ref="prescriptionform" :model="prescriptionform">
        <el-descriptions class="margin-top prescriptiondialog" :column="1" size="medium" border>
          <el-descriptions-item>
            <template slot="label">
              分类
            </template>
            <el-cascader ref="cascader" v-model="prescriptionform.category" :options="options123" @change="changeelcascader"></el-cascader>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              功用
            </template>
            <el-input v-model="prescriptionform.effect" placeholder="请输入内容"></el-input>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              主治
            </template>
            <el-input v-model="prescriptionform.attending" placeholder="请输入内容"></el-input>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              来源
            </template>
            <el-input v-model="prescriptionform.source" placeholder="请输入内容"></el-input>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label">
              名称
            </template>
            <el-input v-model="prescriptionform.name" placeholder="请输入内容"></el-input>
          </el-descriptions-item>
        </el-descriptions>
      </el-form>
      <div class="zycfbox">
        <div class="zycftitle">中药处方</div>
        <div class="prescriptions">
          <div class="zycfitem" @mouseenter="handleMouseEnter(index)" @mouseleave="handleMouseLeave(index)" v-for="(zhongyao,index) in zhcnprescription" :key="index">
            <div>{{ zhongyao.fullName }}</div>
            <div class="count-wrapper">
              <div class="zycf-form-item">
                <div class="zycf-form-item-content">
                  <div data-cy="input-count" class="zycf-input-wrapper count zycf-input-small-wrapper">
                    <input type="text" :id="'input'+index" @input="restrictToPositiveInteger(zhongyao)" v-model="zhongyao.zhongyaocount" autocomplete="off" spellcheck="false" maxlength="6" placeholder="用量" class="zycf-input__inner" style="border: none; outline: none;text-align: right;width: 100%; margin: 0px -1px 0px 0px;">
                  </div>
                  <span class="input-append-unit">g</span>
                </div>
              </div>
            </div>
            <div class="delete-icon-wrapper" @click="deletezhcnrecipes(index)" v-if="showDeleteIcon[index]">
              <div class="delete-icon">
                <i class="el-icon-error" style="font-size: 20px; line-height: 20px;"></i>
              </div>
            </div>
          </div>
          <div class="zycfitem">
            <div>
              <el-popover
                trigger="manual"
                v-model="showPopupzhcnrecipe"
                placement="bottom"
                width="100%">
                <el-table
                  @row-click="handleRowClickzhcnrecipe"
                  class="my-div" 
                  :data="gridDataceshi3"
                  height="100%"
                  style="min-height: 300px;max-height: 400px; overflow-y: auto;"
                  :header-cell-style="{background:'#409eff', color:'#ffffff', 'text-align': 'center', padding: '5px', 'font-size': '14px'}"
                >
                  <el-table-column width="360" align="center"  property="fullName" label="项目名/商品名"></el-table-column>
                  <el-table-column show-overflow-tooltip style="text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" width="100" align="center"  property="displayspec" label="单位/规格"></el-table-column>
                  <el-table-column width="80" align="center" label="库存" style="text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                    <template slot-scope="scope">
                      {{ scope.row.inventory + scope.row.packageUnit}}
                    </template>
                  </el-table-column>
                  <el-table-column width="80" align="center" label="价格">
                    <template slot-scope="scope">
                      {{ '¥' +scope.row.sellprice }}
                    </template>
                  </el-table-column>
                  <el-table-column show-overflow-tooltip style="text-align: center;overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" width="80" property="manufacturer" label="厂家"></el-table-column>
                </el-table>
                <el-input
                  class="zycf-input__inner"
                  slot="reference"
                  @input="searchchinesemedicineInput"
                  @focus="zhcnrecipeInputFocus"
                  @blur="zhcnrecipefocusblur"
                  placeholder="药品"
                  type="text"
                  v-model="searchZynameitem"
                  style="border: none; outline: none; background: transparent; width: 100%; box-sizing: border-box;">
                </el-input>
              </el-popover>
            </div>
            <div class="count-wrapper">
              <div class="zycf-form-item">
                <div class="zycf-form-item-content">
                  <div data-cy="input-count" class="zycf-input-wrapper count zycf-input-small-wrapper">
                    <input type="text" autocomplete="off" spellcheck="false" maxlength="6" placeholder="用量" class="zycf-input__inner" style="border: none; outline: none;text-align: right;width: 100%; margin: 0px -1px 0px 0px;">
                  </div>
                  <span class="input-append-unit">g</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="zycfbottom flexrow">
          <div class="flex-item—1">
            <div class="nested-flex">
              <div class="zycfdose flexrow">
                <input type="text" v-model="dosecount" class="input-element">
                <div class="child-div">剂</div>
              </div>
              <div class="zycfusage" style="text-align: center;">
                <div class="bwhl-form-item is-required">
                  <div class="bwhl-form-item-content">
                    <div data-cy="select-usages" class="bwhl-input-wrapper group-selector-wrapper">
                      <div class="bwhl-input-wrapper count-center">
                        <el-popover
                          placement="bottom"
                          v-model="showPopupzycfusageselect"
                          trigger="manual">
                          <div class="selectcontainer">
                            <div class="column" @click="handlezycfUsageClick(itemusage)" v-for="(itemusage,usageindex) in liTextArray1" :key="usageindex">{{ itemusage }}</div>
                          </div>
                          <input 
                            slot="reference" 
                            @focus="InputFocuszycfusageselect"
                            @blur="Inputblurzycfusageselect" 
                            v-model="usages" 
                            type="text" 
                            autocomplete="off" 
                            spellcheck="false" 
                            maxlength="500" 
                            placeholder="用法" 
                            class="zycfusage-input__inner">
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="zycfusage" style="text-align: center;">
                <div class="bwhl-form-item is-required">
                  <div class="bwhl-form-item-content">
                    <div data-cy="select-usages" class="bwhl-input-wrapper group-selector-wrapper">
                      <div class="bwhl-input-wrapper count-center">
                        <el-popover
                          placement="bottom"
                          :value="showPopupzycfdoseselect"
                          trigger="manual">
                          <div class="selectcontainer">
                            <div class="column" @click="handlezycfdoseClick(itemusage)" v-for="(itemusage,index) in liTextArray2" :key="index">{{ itemusage }}</div>
                          </div>
                          <input 
                            slot="reference" 
                            @focus="InputFocuszycfdose"
                            @blur="Inputblurzycfdose" 
                            v-model="dose" 
                            type="text" 
                            autocomplete="off" 
                            spellcheck="false" 
                            maxlength="500" 
                            placeholder="剂量" 
                            class="zycfusage-input__inner">
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="zycfusage" style="text-align: center;">
                <div class="bwhl-form-item is-required">
                  <div class="bwhl-form-item-content">
                    <div data-cy="select-usages" class="bwhl-input-wrapper group-selector-wrapper">
                      <div class="bwhl-input-wrapper count-center">
                        <el-popover
                          placement="bottom"
                          v-model="showPopupuzycffrequency"
                          trigger="manual">
                          <div class="selectcontainer">
                            <div class="column" @click="handlezycffrequencyClick(itemusage)" v-for="(itemusage,index) in liTextArray3" :key="index">{{ itemusage }}</div>
                          </div>
                          <input 
                            slot="reference" 
                            @focus="InputFocuszycffrequency"
                            @blur="Inputblurzycffrequency" 
                            v-model="frequency" 
                            type="text" 
                            autocomplete="off" 
                            spellcheck="false" 
                            maxlength="500" 
                            placeholder="频率" 
                            class="zycfusage-input__inner">
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="zycfusage" style="text-align: center;">
                <div class="bwhl-form-item is-required">
                  <div class="bwhl-form-item-content">
                    <div data-cy="select-usages" class="bwhl-input-wrapper group-selector-wrapper">
                      <div class="bwhl-input-wrapper count-center">
                        <el-popover
                          placement="bottom"
                          v-model="showPopupudosagetaken"
                          trigger="manual">
                          <div class="selectcontainer">
                            <div class="column" @click="handlezycfdosagetakenClick(itemusage)" v-for="(itemusage,index) in liTextArray4" :key="index">{{ itemusage }}</div>
                          </div>
                          <input
                            style="width: 100px;" 
                            slot="reference" 
                            @focus="InputFocuszycfdosagetaken"
                            @blur="blurzycfdosagetaken" 
                            v-model="dosagetaken" 
                            type="text" 
                            autocomplete="off" 
                            spellcheck="false" 
                            maxlength="500" 
                            placeholder="服用量" 
                            class="zycfusage-input__inner">
                        </el-popover>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-item align-right jend">
            <div class="flexrow mr10">
              <div class="mr10">{{ zhcnrecipequantity() }} 味</div>
              <div class="mr10">单剂 {{ zhcnrecipequantitytotal() }}g</div>
              <div class="mr10">总共 {{ zhcnrecipequantitytotal()*dosecount }}g</div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addprescriptiondialog = false">取 消</el-button>
        <el-button v-if="prescriptionform._id" type="success" @click="updataMedicineTemplate">更 新</el-button>
        <el-button v-if="!prescriptionform._id" type="primary" @click="saveMedicineTemplate">保 存</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>
<script>
import unitsData from "@/utils/unit";
export default {
  data() {
    return {
      liTextArray1:[ "煎服", "冲服", "制膏", "制丸", "外用", "贴敷", "打粉", "泡饮", "泡酒", "泡浴", "泡脚", "擦拭" ],
      liTextArray2:[ "1日1剂", "1日2剂", "2日1剂", "1日半1剂"],
      liTextArray3:[ "1日1次", "1日2次", "1日3次", "1日4次", "1日5次", "1日6次", "2日1次", "隔日1次", "1周1次", "1周2次", "1周3次", "隔周1次", "必要时", "顿服", "少量频服", "随时服用" ],
      liTextArray4:[ "每次10ml", "每次20ml", "每次30ml", "每次40ml", "每次50ml", "每次60ml", "每次70ml", "每次80ml", "每次90ml", "每次100ml", "每次120ml", "每次150ml", "每次180ml", "每次200ml", "每次250ml", "每次300ml" ],
      dosecount: 1,
      usages: "",
      dose: "",
      frequency: "",
      dosagetaken: "",
      showPopupzycfusageselect: false,
      showPopupzycfdoseselect: false,
      showPopupuzycffrequency: false,
      showPopupudosagetaken: false,
      searchZynameitem: "",
      gridDataceshi3:[],
      zhcnprescription:[],
      showPopupzhcnrecipe: false,
      dialogFormVisible: false,
      dialogdetailVisible: false,
      addprescriptiondialog: false,
      innerVisible: false,
      CategoryData: [],
      options: [],
      categoriesoptions: [],
      categorieslist: [],
      categoriesform:{},
      prescriptionform:{
        _id:"",
        categoryid:"",
        categoryname:"",
        name:"",
        effect:"",
        source:"",
        attending:"",
      },
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页数据量
      total: 0, // 总数据量
      items: [],
      timeslot: "",
      search: "",
      detailtitle: "",
      detaildata: [],
      searchdrug: "",
      searchdate: "",
      zhcndrugnum: 0,
      drugnummax: null,
      zhcndrugData: [],
      savezhcndrugs: [],
      selectionzhcndrug:[],
      zhprescribetitle: "",
      prescribenum: 0,
      zhcncurrentPage: 1, // 当前页码
      zhcnpageSize: 10, // 每页数据量
      zhcntotal: 0, // 总数据量
      units: unitsData,
      form: {
        _id: "",
        title: "",
        zhcnrecipe:[],
        creator: "",
        editor: "",
        createdAt: "",
        updatedAt: "",
      },
      options123: [],
      showDeleteIcon:[]
    };
  },
  computed: {},
  methods: {
    changeelcascader(val){
			let nodesInfo = this.$refs['cascader'].getCheckedNodes()
      this.prescriptionform.categoryid = nodesInfo[0].value
      this.prescriptionform.categoryname = nodesInfo[0].label
		},
    deletezhcnrecipes(index) {
      this.zhcnprescription.splice(index, 1);
    },
    handleMouseEnter(index) {
      this.$set(this.showDeleteIcon, index, true);
    },
    handleMouseLeave(index) {
      this.$set(this.showDeleteIcon, index, false);
    },
    //保存处方
    saveMedicineTemplate(){
      const ingredients = this.zhcnprescription.filter(item => item.zhongyaocount > 0).map(item => ({
        drug: item._id,
        quantity: item.zhongyaocount
      }));
      const data = {
        category:this.prescriptionform.category,
        categoryid:this.prescriptionform.categoryid,
        categoryname:this.prescriptionform.categoryname,
        name:this.prescriptionform.name,
        effect:this.prescriptionform.effect,
        source:this.prescriptionform.source,
        attending:this.prescriptionform.attending,
        ingredients:ingredients,
        dosecount: this.dosecount,
        usages: this.usages,
        dose: this.dose,
        frequency: this.frequency,
        dosagetaken: this.dosagetaken
      }
      if(!data.name && data.categoryid !== undefined ){
          return this.$message({
            message: "处方名称以及分类不能为空",
            type: "warning",
          });
      }
      if(data.ingredients.length === 0){
          return this.$message({
            message: "没有需要保存的药品",
            type: "warning",
          });
      }
      this.$api.templates.saveMedicineTemplate(data).then((res) => {
        if(res.data.code === 200){
          this.addprescriptiondialog = false
          this.loadItems();
        }
        this.$message({
          message: res.data.msg,
          type: res.data.code === 200?"success":"warning",
        });
      })
    },
    //更新处方
    updataMedicineTemplate(){
      const ingredients = this.zhcnprescription.filter(item => item.zhongyaocount > 0).map(item => ({
        drug: item._id,
        quantity: item.zhongyaocount
      }));
      const data = {
        _id:this.prescriptionform._id,
        category:this.prescriptionform.category,
        name:this.prescriptionform.name,
        effect:this.prescriptionform.effect,
        source:this.prescriptionform.source,
        attending:this.prescriptionform.attending,
        ingredients:ingredients,
        dosecount: this.dosecount,
        usages: this.usages,
        dose: this.dose,
        frequency: this.frequency,
        dosagetaken: this.dosagetaken
      }
      if(!data.name && data.category !== undefined ){
          return this.$message({
            message: "处方名称以及分类不能为空",
            type: "warning",
          });
      }
      if(data.ingredients.length === 0){
          return this.$message({
            message: "没有需要保存的药品",
            type: "warning",
          });
      }
      this.$api.templates.updataMedicineTemplate(data).then((res) => {
        if(res.data.code === 200){
          this.addprescriptiondialog = false
          this.loadItems();
        }
        this.$message({
          message: res.data.msg,
          type: res.data.code === 200?"success":"warning",
        });
      })
    },
    convertToOptionsArray(data) {
      return data.map(item => {
        const option = {
          value: item._id,
          label: item.name
        };
        if (item.children && item.children.length > 0) {
          option.children =  this.convertToOptionsArray(item.children);
        }
        return option;
      });
    },
    zhcnrecipequantitytotal() {
      const filteredProductInfos = this.zhcnprescription.filter(i => i.zhongyaocount > 0);
      const total = filteredProductInfos.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.zhongyaocount), 0);
      return total;
    },
    zhcnrecipequantity() {
      const filteredProductInfos = this.zhcnprescription.filter(i => i.zhongyaocount > 0);
      return filteredProductInfos.length
    },
    InputFocuszycfdosagetaken(){
      this.showPopupudosagetaken = true
    },
    blurzycfdosagetaken(){
      this.showPopupudosagetaken = false
    },
    handlezycfdosagetakenClick(itemusage) {
      this.dosagetaken = itemusage;
    },
    InputFocuszycffrequency(){
      this.showPopupuzycffrequency = true
    },
    Inputblurzycffrequency(){
      this.showPopupuzycffrequency = false
    },
    handlezycffrequencyClick(itemusage) {
      this.frequency = itemusage;
    },
    InputFocuszycfdose(){
      this.showPopupzycfdoseselect = true
    },
    Inputblurzycfdose(){
      this.showPopupzycfdoseselect = false
    },
    handlezycfdoseClick(itemusage) {
      this.dose = itemusage;
    },
    Inputblurzycfusageselect(){
      this.showPopupzycfusageselect = false
    },
    InputFocuszycfusageselect(){
      this.showPopupzycfusageselect=true
    },
    handlezycfUsageClick(itemusage) {
      this.usages = itemusage;
    },
    restrictToPositiveInteger(item) {
      // 使用正则表达式验证输入的值
      const regex = /^(?!0\d)(\d+(\.\d{0,2})?)?$/;
      if (!regex.test(item.zhongyaocount)) {
        // 如果输入的值不符合要求，则将其设为空字符串
        item.zhongyaocount = null;
      }
    },
    debounce(func, delay) {
      clearTimeout(this.timer);
      this.timer = setTimeout(func, delay);
    },
    searchchinesemedicineInput(){
      this.debounce(this.searchvisitchinesemedicine(), 500);
    },
    searchvisitchinesemedicine(){
      const data = {
        search:this.searchZynameitem
      }
      this.$api.goods.searchvisitchinesemedicine(data).then((res) => {
        if(res.data.code === 200){
          this.gridDataceshi3 = res.data.data
        }
      })
    },
    zhcnrecipeInputFocus() {
      this.showPopupzhcnrecipe = true
      this.searchvisitchinesemedicine()
    },
    zhcnrecipefocusblur() {
      this.showPopupzhcnrecipe = false
    },
    handleRowClickzhcnrecipe(row){
      const newConsulting = { ...row }; // 创建新的对象并复制行数据
      this.zhcnprescription.push(newConsulting);
      this.searchZynameitem = ""
      this.$nextTick(() => {
        const refName = `input${this.zhcnprescription.length-1}`;
        console.log(refName)
        const inputElement = document.getElementById(refName);
        if (inputElement) {
          inputElement.focus();
        }
      });
    },
    editcategories(data){
      console.log(data)
      const editData = JSON.parse(JSON.stringify(data))
      this.innerVisible = true
      this.categoriesform = {
        ...editData,
        subcategories:editData.parent,
        permissions: editData.permissions.map(item => item._id)
      };
    },
    categories(data) {
      this.$refs.categoriesform.validate((valid) => {
          if (valid) {
            this.$api.templates.categories(data).then((res) => {
              
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.categorieslist = res.data.data
              this.innerVisible = false;
              this.getpermissionscategories();
            });
          } else {
            this.$message({
                message: "请按规则填写",
                type: "warning",
            });
            return false;
          }
      });
    },
    updatacategories(data) {
      this.$refs.categoriesform.validate((valid) => {
          if (valid) {
            this.$api.templates.editcategories(data).then((res) => {
              this.$message({
                message: res.data.msg,
                type: "success",
              });
              this.innerVisible = false;
              this.allcategories();
            });
          } else {
            this.$message({
                message: "请按规则填写",
                type: "warning",
            });
            return false;
          }
      });
    },
    getpermissionscategories() {
      this.$api.templates.getpermissionscategories().then((res) => {
          this.categoriesoptions = res.data.data
          this.options123 = this.convertToOptionsArray(this.categoriesoptions)
      })
    },
    getroleid(){
      this.$api.roleids.getroleidlist().then(res =>{
          this.options = res.data.data
      })
    },
    closeinnerVisibledialog(){
      this.innerVisible = false;
      this.categoriesform = {};
    },
    closedialog(){
      this.dialogFormVisible = false
      this.savezhcndrugs = []
      this.zhprescribetitle = ""
    },
    zhcnhandlePageChange(page) {
      this.zhcncurrentPage = page;
      this.allcategories();
    },
    zhcnhandlePageSizeChange(pageSize) {
      this.zhcnpageSize = pageSize;
      this.allcategories();
    },
    checkDuplicate(arr, newarr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i]._id === newarr) {
          return false; // Duplicate found
        }
      }
      return true; // No duplicate found
    },
    searchzhcndrug() {
      this.selectionzhcndrug = [];
      this.drugnummax = 0;
      const data = {
        currentPage: this.zhcncurrentPage,
        pageSize: this.zhcnpageSize,
        searchdrug: this.searchdrug,
      };
      if (!data.searchdrug) {
        return this.$message({
          message: "请输入需要添加的药物名称进行查询",
          type: "danger",
        });
      }
      this.$api.inventory.zhcndrugpagination(data).then((res) => {
        this.zhcncurrentPage = res.data.pagination.currentPage;
        this.zhcnpageSize = res.data.pagination.pageSize;
        this.zhcntotal = res.data.pagination.total;
        this.zhcndrugData = res.data.data;
      });
    },
    Changezhcndrug(selection,val) {
      // 将选中的数据存储起来
      if(selection.length === 1 ){
        this.drugnummax = selection[0].stock;
        this.selectionzhcndrug = selection;
      }else{
        this.drugnummax = 0;
        this.selectionzhcndrug = []
      }
    },
    clocedialog() {
      this.dialogFormVisible = false;
    },
    openprescriptiondialog() {
      this.addprescriptiondialog = true;
      this.prescriptionform = {}
      this.dosecount = 0
      this.usages = ""
      this.dose = ""
      this.frequency = ""
      this.dosagetaken = ""
      this.zhcnprescription = []
    },
    opendialog() {
      this.dialogFormVisible = true;
      this.savezhcndrugs = []
      this.zhcndrugData= []
      this.zhprescribetitle = ""
      this.zhcndrugnum = 0
      this.searchdrug = ""
    },
    handleedit(data) {
      const updata = JSON.parse(JSON.stringify(data))
      this.addprescriptiondialog = true
      this.prescriptionform._id = updata._id
      this.prescriptionform.category = updata.category
      this.prescriptionform.name = updata.name
      this.prescriptionform.effect = updata.effect
      this.prescriptionform.source = updata.source
      this.prescriptionform.attending = updata.attending
      this.dosecount = updata.dosecount
      this.usages = updata.usages
      this.dose = updata.dose
      this.frequency = updata.frequency
      this.dosagetaken = updata.dosagetaken
      const ingredientsData = updata.ingredients.map(item => {  
        const { drug, quantity } = item; // 解构 drug 和 quantity 属性  
        return { ...drug, zhongyaocount:quantity }; // 将 drug 对象合并到新对象中并返回  
      }); 
      this.zhcnprescription = ingredientsData
    },
    handledetail(data) {
      this.dialogdetailVisible = true;
      this.detaildata = data.zhcnrecipe
      this.detailtitle = data.title
      console.log(data)
    },
    deletecategories(data) {
      this.$api.templates.deletecategories(data).then((res) => {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        this.allcategories();
      });
    },
    deleteMedicineTemplates(data) {
      this.$api.templates.deleteMedicineTemplates(data).then((res) => {
        this.$message({
          message: res.data.msg,
          type: "success",
        });
        this.loadItems();
      });
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loadItems();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.loadItems();
    },
    loadItems() {
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      this.$api.templates.allMedicineTemplates(data).then((res) => {
        this.currentPage = res.data.pagination.currentPage;
        this.pageSize = res.data.pagination.pageSize;
        this.total = res.data.pagination.total;
        this.items = res.data.data;
      });
    },
    allcategories() {
      const data = {
        currentPage: this.zhcncurrentPage,
        pageSize: this.zhcnpageSize,
      };
      this.$api.templates.allcategories(data).then((res) => {
        this.zhcncurrentPage = res.data.pagination.currentPage;
        this.zhcnpageSize = res.data.pagination.pageSize;
        this.zhcntotal = res.data.pagination.total;
        this.categorieslist = res.data.data;
      });
    },
    formatTime(row, column) {
      let data = row[column.property];
      let dtime = new Date(data);
      const year = dtime.getFullYear();
      let month = dtime.getMonth() + 1;
      if (month < 10) {
        month = "0" + month;
      }
      let day = dtime.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      let hour = dtime.getHours();
      if (hour < 10) {
        hour = "0" + hour;
      }
      let minute = dtime.getMinutes();
      if (minute < 10) {
        minute = "0" + minute;
      }
      let second = dtime.getSeconds();
      if (second < 10) {
        second = "0" + second;
      }
      return year + "-" + month + "-" + day;
    },
  },
  created() {
    this.allcategories()
    this.loadItems();
    this.getroleid()
    this.getpermissionscategories()
  },
};
</script>
<style>
.delete-icon-wrapper {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1000;
}
.jend{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.zycfusage-input__inner{
  width: 72px;
  border: none;
  text-align: center;
  margin: 0 5px;
  outline: none;
}
.zycfdose {
  border-bottom: 1px solid #d1d1d1;
}
.flexrow{
  display: flex;
  flex-direction: row;
}
.nested-flex {
  display: flex;
  align-items: center;
}
.input-element {
  width: 40px;
  border: none;
  outline: none;
  text-align: center;
  line-height: 1;
}
.zycfitem .zycf-form-item-content{
  display: flex;
}
.zycfitem .zycf-input-small-wrapper {
  line-height: 1;
}
.zycfitem .zycf-input-wrapper {
  display: inline-block;
  font-size: 14px;
  outline: none;
  position: relative;
}
.zycfitem .zycf-form-item {
  margin: 0;
}
.zycfitem .count-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 15px;
  width: 60px;
}
.zycfitem .el-input__inner {
  border: none;
  height: 32px;
  line-height:32px;
  padding: 0;
}
.zycfbottom{
  border-top: 1px solid #EBEEF5;
  margin-top: -1px;
  padding:10px 10px;
  justify-content: space-between;
}
.mr10{
  margin-right: 10px;
} 
.zycftitle {
  display: flex;
  flex-wrap: wrap;
}
.prescriptions{
  display: flex;
  flex-wrap: wrap;
}
.zycfitem{
  position:relative;
}
.prescriptions .zycfitem {
  width: 25%; /* 每个div占据四等分的宽度 */
  border-right: 1px solid #EBEEF5; /* 添加右边框 */
  border-bottom: 1px solid #EBEEF5; /* 添加右边框 */
  box-sizing: border-box;
  height: 50px;
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.prescriptions .zycfitem:nth-child(4n) {
  border-right: none; /* 第四个div去掉右边框 */
}

.zycftitle{
  padding: 10px 10px;
  border-bottom: 1px solid #EBEEF5;
}
.zycfbox{
  margin-top: 10px;
  border: 1px solid #EBEEF5;
}
.el-descriptions .is-bordered .el-descriptions-item__cell {
  border: 1px solid #EBEEF5;
  padding: 0px 10px;
}
.prescriptiondialog .el-cascader {
  line-height: 32px;
  width: 100%;
}
.prescriptiondialog .el-input__inner {
  border: none;
  height: 32px;
  line-height:32px;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 250px;
}
.w250 {
  width: 250px;
}
.w120 {
  width: 120px;
}
.flex {
  display: flex;
}
.justifyend {
  justify-content: end;
}
button:focus {
  outline: none;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 100%;
}
</style>
